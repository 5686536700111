<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ user.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ user.firstname }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ user.lastname }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ user.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
          <dd class="col-sm-8">
            {{ user.phone }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCALE") }}</dt>
          <dd class="col-sm-8">
            {{
              user.locale
                ? $t(`COMMON.LOCALE_${user.locale.toUpperCase()}`)
                : null
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("USERS.ACTIVE") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!user.active" />
          </dd>
        </dl>
        <dl class="row" v-if="$currentUser() && $currentUser().is_staff">
          <dt class="col-sm-4">{{ $t("USERS.IS_STAFF") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!user.is_staff" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ROLE") }}</dt>
          <dd class="col-sm-8">
            <router-link
              v-if="user.roles[0]"
              :to="{ name: 'View Role', params: { id: user.roles[0].id } }"
            >
              {{ user.roles[0].name }}
            </router-link>
            <span v-else> {{ $t("COMMON.NONE") }} </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("USERS.RESTRICT_TO_LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!user.restrict_to_locations" />
          </dd>
        </dl>
        <dl class="row" v-if="user.restrict_to_locations">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="user.allowedLocations" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="user.organization" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="user.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="user.created_at">
            {{ user.created_at | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="user.updated_at">
            {{ user.updated_at | moment("LLLL") }}
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view :billingInformations="user" />
      </div>

      <div class="col-3">
        <img
          v-if="user.profile_image"
          :src="`${user.profile_image}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
    </div>
  </div>
</template>
<script>
import IconCheck from "@/components/IconCheck.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "user-view-global",

  components: { IconCheck, BillingInformationsView },

  props: ["user"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
