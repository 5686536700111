<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ `${user.firstname} ${user.lastname}` }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editProfile()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>
              </h3>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <user-view-global :user="user" />
                </tab-pane>

                <tab-pane title="authorizations" id="2">
                  <span slot="title">
                    <i class="ni ni-key-25" />
                    {{ $t("USERS.AUTHORIZATIONS") }}
                  </span>
                  <user-view-authorizations :user="user" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <user-view-logs :user="user" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import UserViewGlobal from "@/views/Pages/AdminModule/UserManagement/partials/UserViewGlobal.vue";
import UserViewAuthorizations from "@/views/Pages/AdminModule/UserManagement/partials/UserViewAuthorizations.vue";
import UserViewLogs from "@/views/Pages/AdminModule/UserManagement/partials/UserViewLogs.vue";
import defaultUser from "@/views/Pages/AdminModule/UserManagement/defaultUser";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    UserViewGlobal,
    UserViewAuthorizations,
    UserViewLogs,
  },

  mixins: [],

  data() {
    return {
      user: cloneDeep(defaultUser),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("profile/me");
        this.user = this.$store.getters["profile/me"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editProfile() {
      this.$router.push({
        name: "Edit Profile",
      });
    },
  },
};
</script>
